import React, { useState,useEffect  } from 'react';
import Cookies from "js-cookie";
import logo from "../src/images/logo.webp";
import Question from "../src/images/chatIcon.jpg";
import Facebook from "../src/images/fb.webp";
import Youtube from "../src/images/youtube.webp";
import Tiktok from "../src/images/tiktok.webp";
import Insta from "../src/images/insta.webp";
import Input from 'react-phone-number-input/input'
import 'react-phone-number-input/style.css';
import { Helmet } from 'react-helmet';

const ThankYou = () => {

  useEffect(() => { 
    const refreshData = Cookies.get("refresh"); 
    if(refreshData>11){ 
    window.location.reload();
    Cookies.set("refresh", JSON.stringify(1));
    }
    else{ 
    Cookies.set("refresh", JSON.stringify(1));
  
    }
    // window.location.reload();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  useEffect(() => {
  }, []);
  const [key, setKey] = useState(0);
  const userDataString = Cookies.get("UserData");
  
  
    if (!userDataString) {
       window.location.replace('https://class.ourrealsuccess.com/');
    } else{
      
      var userData = JSON.parse(userDataString);
    }
   
  const stringValue = userData.formattedDate.replace(/-/g, '');
    const startTimeing = userData.startTime.replace(/:|\s/g, '');
    // const endTimeing = userData.endTime.replace(/:|\s/g, '');
   const address = `${userData.hotel}, ${userData.street}, ${userData.city},${userData.state} ${userData.zipcode}`;
   const completeAddress = address.replace("&", "%26");
  
  const endTimeCL = userData.endTime;
  const endTimeing = userData.endTime.replace(/:/g, "");
  
    const [formValues, setFormValues] = useState({ 
      guestFirstName: '', 
      guestLastName: '', 
      guestEmail: '',
      guestNumber: ''
  });
  const [errors, setErrors] = useState({ guestFirstName: '',guestLastName: '', guestEmail: '', guestNumber:'' });
  const [success, setSuccess] = useState(false)
  
  const [isLoading, setIsLoading] = useState(false);
   
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    
    let valid = true;
    const newErrors = { guestFirstName: '',guestLastName: '', guestEmail: '', guestNumber:'' };
  
  
    if (formValues.guestFirstName.trim() === '') {
      newErrors.guestFirstName = 'First Name is required';
      valid = false;
    }
    if (formValues.guestLastName.trim() === '') {
      newErrors.guestLastName = 'Last Name is required';
      valid = false;
    }     
    if (formValues.guestNumber.trim() === "") {
      newErrors.guestNumber = "Phone number is required";
      valid = false;
    } else if(formValues.guestNumber.length<12){
      newErrors.guestNumber = "Correct Phone number is required";
      valid = false;
    }
    else if(formValues.guestNumber.length>12) { 
      newErrors.guestNumber = "Correct Phone number is required";
      valid = false;
    }
    if (userData.email === formValues.guestEmail) {
      newErrors.guestEmail = 'This email is already being used, Please use guest email.';
      valid = false; 
    } 
    if (userData.number === formValues.guestNumber) {
      newErrors.guestNumber = 'This number is already being used, Please use guest number.';
      valid = false; 
    } 
    if (formValues.guestEmail.trim() === '') {
      newErrors.guestEmail = 'Guest Email is required';
      valid = false;
    } else{
      // setFormValues(initialFormValues);
      // Clear the error messages
      setErrors({});
    }
    const updatedFormValues = {   
      
      guestFirstName: formValues.guestFirstName, 
      guestLastName: formValues.guestLastName, 
      guestEmail: formValues.guestEmail, 
      guestNumber: formValues.guestNumber, 
      primaryContactFirstName :userData.fname,
      primaryContactLastName :userData.lname,
      dateName: userData.dateName, 
      monthName:userData.monthName,
      dateStr:userData.dateStr,
      dayName:userData.dayName,
      selectedOption:userData.selectedOption,
      startTime:userData.startTime, 
      endTime:userData.endTime, 
      formattedDate:userData.formattedDate,
      hotel:userData.hotel,
    street:userData.street,
    lobyName:userData.lobyName,
    city:userData.city,
    zipcode:userData.zipcode,
    state:userData.state,
    hotelPhone:userData.hotelPhone,
    campaign:userData.campaign
    
   };
  
  
    if (valid) { 
      const response = await fetch('https://services.leadconnectorhq.com/hooks/kJOiQyFsJQQj5rYEbrCO/webhook-trigger/f7cccf6f-8bad-4f3f-af2d-77932f1f6c7b', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedFormValues)
      });
  
      if (response.ok) {
        setSuccess(true);
        // Code to handle successful form submission goes here
   
        setIsLoading(false);
  
        window.location.replace('https://realsuccesseducation.com/2-hour-class-ty');
  
      } else {
        // Code to handle form submission error goes here
   
      }
    } else {
      setIsLoading(false);
  
      setErrors(newErrors);
    }
  }
  
  
  
  
  //   "20230315T1230PM"
    const formSrc = `https://www.google.com/calendar/render?action=TEMPLATE&text=2 Hour Workshop - RealSuccess Investments &dates=${ stringValue }T${ startTimeing }/${ stringValue }T${ endTimeing }&details=Join us to the Real Estate Investment Workshop on. We will show you how to flip houses, own rentals, and wholesale. Be part of our Passive Income Club by using our own Proven Systems. &location=${completeAddress}`;
  
    return (
      <div className="thank-you-page">
        <Helmet key={key}>
  {/* <!-- Facebook Pixel Code --> */}
  
          <script>{`
           !function(f,b,e,v,n,t,s)
           {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
           n.callMethod.apply(n,arguments):n.queue.push(arguments)};
           if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
           n.queue=[];t=b.createElement(e);t.async=!0;
           t.src=v;s=b.getElementsByTagName(e)[0];
           s.parentNode.insertBefore(t,s)}(window, document,'script',
           'https://connect.facebook.net/en_US/fbevents.js');
           fbq('init', '319878429693238');
           fbq('track', 'Lead');
          `}</script>
  
  {/* <!-- TIKTOK Pixel Code --> */}
  <script>{`
           !function (w, d, t) {
            w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
          
            ttq.load('CFUH7KBC77U840VNCFO0');
            ttq.track('CompleteRegistration')
            ttq.page();
          }(window, document, 'ttq');
          `}</script>
  
        </Helmet>
  

      <div className="thank-header page-cointaner">
        <h1><i>JOE'S</i>  REAL ESTATE CLASS</h1>
    
           {/* <div className="header-inner">
           <img src={Question} alt="Question" />
            <p>
              Question? <br />
              Call 1-800-304-1799
            </p>
           </div> */}
          
      </div>
      <div className="congraultion">
        <div className='congraulation-inner'>
          
        <div className="page-cointaner">
          <h2>Congratulations, {userData.fname} !</h2>
          <p>You are now registered!</p>
    <h6 className='color-white'>Your event confirmation & Funding Deals Guide will be sent to you <em>{userData.email}</em> shortly.</h6>

        </div>
        </div>

      </div>
<div className='socail-links'>
  <h2>Follow Joe Arias</h2>
<div className='socail-links-inner'>
  <div>
    
          <a href="https://www.instagram.com/joeariasinvestor/?__coig_restricted=1" target="_blank"> <img src={Insta} alt="Insta" /></a>
  </div>
  <div>
          <a href="https://www.facebook.com/joeariasinvestor?paipv=0&eav=Afb9afcegchgdVHi2aRrScZyH8ENEHbMJ2yl8OYXt7_Yv_RkMQoXxVhk32d3K5iX7lM&_rdr" target="_blank"> <img src={Facebook} alt="Facebook" /></a>
    
    </div>
    <div>
          <a href="https://www.tiktok.com/@joearias_investor?is_from_webapp=1&sender_device=pc" target="_blank"><img src={Tiktok} alt="Tiktok" /></a>
    
    </div>
    <div>
          <a href="https://www.youtube.com/channel/UCif98Y0uvl2_5Xh1ZhjLe5g" target="_blank"> <img src={Youtube} alt="Youtube" /></a>
    
    </div>
  
</div>
</div>

      <div>
        <div className="page-cointaner next-step">
          <div className="nextstep ">
            <h2>You Next Step: Register Your Guest</h2>
            <iframe src="https://player.vimeo.com/video/625026316?h=512ad66c19&title=0&byline=0&portrait=0"   allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={{ width: '100%', height: '100%' }}></iframe>

            <div>
              <p>
                As part of your class registration, you have received <strong>one (1) Free
                Guest Ticket.</strong> Take Advantage of it &amp; bring a spouse, friend
                or business partner with you!
              </p>
            </div>

            <form onSubmit={handleSubmit} className="modal-form thank-you-form">
      <label>
      Guest First Name
        <input type="text"  name="guestFirstName" value={formValues.guestFirstName}  onChange={(event) => setFormValues({ ...formValues, guestFirstName: event.target.value })} />
        {errors.guestFirstName && <div className="error">{errors.guestFirstName}</div>}
      </label>
      {/* <br /> */}
      <label>
      Guest Last Name
        <input type="text"  name="guestLastName" value={formValues.guestLastName}  onChange={(event) => setFormValues({ ...formValues, guestLastName: event.target.value })} />
        {errors.guestLastName && <div className="error">{errors.guestLastName}</div>}
      </label>
      {/* <br /> */}
      <label>
      Guest Email
        <input type="email"  name="guestEmail" value={formValues.guestEmail} onChange={(event) => setFormValues({ ...formValues, guestEmail: event.target.value })}/>
        {errors.guestEmail && <div className="error">{errors.guestEmail}</div>}
      </label>
      {/* <br /> */}
      <label> 
      Guest Number
      <Input
  country="US"
  international
  withCountryCallingCode
  name="guestNumber"
  placeholder='Number'
  onChange={(value) => setFormValues({ ...formValues, guestNumber: value })}/>
        {errors.guestNumber && <div className="error">{errors.guestNumber}</div>}
      </label>
      <div className='submit-form'>
      <button type="submit"  className="submit_btn_form" disabled={isLoading}>
        {isLoading ? 'Loading...' : 'Reserve Your Seat'}
      </button>
      {/* <input type="submit" value="Reserve Your Seat" /> */}
      </div>
    </form>



    {/* <h6>Your event confirmation & Money Resource Guide will be delivered to <em>{userData.email}</em> shortly.</h6> */}
{success &&  <h5 className='mt-2 congration_guest'>Congratulations, your guest seat is confirmed. <br/><span>Your guest will receive  their event confirmation shortly.</span></h5>}
            {/* <iframe src="https://api.leadconnectorhq.com/widget/form/0PTCqQDeMNYOm7lKsgq8" width="100%" height="410px"></iframe> */}
          </div>


          <div className="addtocalender page-cointaner next-step">
            <h2>Step 2: Add to Calendar</h2>
            {/* <p>Class starts at <strong>{userData.startTime} </strong>at the <strong>{userData.hotel}</strong> on <strong>{userData.dayName}, {userData.dateStr}</strong>.</p> */}
            <p> <strong>Date: </strong>{userData.dateStr} <br/><strong>Time: </strong>{userData.startTime} <br/><strong>Location: </strong>{userData.hotel}, {userData.street}, {userData.city}, {userData.state} {userData.zipcode}</p>
            <p>This is an event you won't want to miss, please take a moment to add it to your calendar.</p>
          {/* <h2>Add Event To Your Calendar</h2> */}
           
          <div className='add-to-calender'>
            <a
              target="_blank"
              rel="noopener noreferrer nofollow"
              href={formSrc}
            >
              Add To Your Calendar
            </a>
          </div>
        </div>
        
        <div className="addtocalender page-cointaner next-step">
            <h2>Step 3: Arrive Ready To Learn</h2>
            <p>This class will cover the same systems and techniques JOE and his  team have used to buy & sell hundreds of properties over the past 10+ years.</p>

            <p>Bring your favorite note taking device because you will want to write down your favorite strategies and techniques during the class. We will have a Q&A at the end of class to make sure all your questions are answered!</p>
         <p>Other than a little enthusiasm and an open mind as to what's possible for you & your family through real estate!</p>
        </div>


  
        <div className="addtocalender page-cointaner next-step">
        <div className="thank-header page-cointaner">
           <div className="header-inner">
           <img src={Question} alt="Question" />
            <p>
              Question? <br />
              Call 1-800-304-1799
            </p>
           </div>
          
      </div>
        </div>
        
        </div>
      </div>
      <div className="thank-footer">
      <div className="page-cointaner">
          <img src={logo} alt="logo" />
          <p></p>
          <p>Copyright @ 2023. RealSuccess Investments.</p>
          <p>
            We offer free workshops and advanced education. We do not partner up
            with investors or sell a franchise or a done-for-you business.
            Learning real estate takes time and energy, we do not believe people
            can become wealthy in just a few months of investing in real estate.
            It takes effort, commitment, timing on the market, knowledge,
            systems and many other factors. We are not financial consultants and
            do not guarantee earnings or returns on investment. We hace no
            license to offer financial, tax, or legal advice. Investing can be
            riskly and my result in partial or total loss. This site is not
            endorsed by Facebook, Instagram, Google in any way, shape or form.
            RealSuccess Investments is a real estate investment company
            committed to create active and passive income for our investors and
            people we do business with.{" "}
          </p>
          <p></p>
        </div>
      </div>
  
    </div>
  );
};

export default ThankYou;
